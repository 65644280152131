import React from 'react'
import Container from '../components/Container'

const NotFoundPage = ({ location }) => (
  <Container width={1272}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Container>
)

export default NotFoundPage
